import { Injectable, Inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,
              public jwtHelper: JwtHelperService) { }

  public isAuthenticated(): boolean {
    const token = this.storage.get('mgr_access_token');
    return !this.jwtHelper.isTokenExpired(token);
  }
}
