import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  userRole:any;
  constructor(public auth: AuthService, public router: Router, @Inject(LOCAL_STORAGE) private storage: WebStorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.userRole=this.storage.get('user_details');
    if (!this.userRole) {
      this.storage.remove('manager_access');
      this.storage.remove('mgr_access_token');
      this.storage.remove('select_branch');
      this.storage.remove('user_details');
      this.storage.remove('checkinchecklist');
      this.router.navigate(['../login']);
      return false;
    }
    if (!this.auth.isAuthenticated() && (this.userRole.user_role.id !=2 || this.userRole.user_role.id !=4)) {
      this.storage.remove('manager_access');
      this.storage.remove('mgr_access_token');
      this.storage.remove('select_branch');
      this.storage.remove('user_details');
      this.storage.remove('checkinchecklist');
      this.router.navigate(['../login']);
      return false;
    }

    //  this.router.navigate(['/maintenance']);
    return true;
  }
}
