import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LOCAL_STORAGE,  WebStorageService } from 'angular-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  // pusher: any;
//   private pusher: Pusher;
  channel: any;
  outlet_id: String = this.storage.get('outlet_id') === null ? 'my_channel' : this.storage.get('outlet_id');

  constructor(@Inject(LOCAL_STORAGE) private storage:  WebStorageService) {
    
    //console.log(this.outlet_id);
  }
}
