import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import { DataService, Response } from '../dataservice/data.service';
import { GlobalVariable } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public menu_selection = new BehaviorSubject(true);
  public profile_selection = new BehaviorSubject(null);
  public menu_display_selection = new BehaviorSubject(null);
  public roomdata = new BehaviorSubject(null);
  public branchID = new BehaviorSubject(null);
  public studentUserID = new BehaviorSubject(null);
  public bookingId = new BehaviorSubject(null);
  public sidebar_menu = new BehaviorSubject(null);
  public forgotPassmail = new BehaviorSubject(null);
  public checkinChecklistdata  = new BehaviorSubject(null);
  public userDetail = new BehaviorSubject(null);
  public ticketID = new BehaviorSubject(null);
  public waitID = new BehaviorSubject(null);

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) { }

  /**
   * Side menu selection based on send review invite click
   *
   * @param {*} status
   * @memberof
   */
  sideMenuSelection(status) {
    this.menu_selection.next(status);
  }

  sideMenuBar(status) {
    this.sidebar_menu.next(status);
  }

  profile(client){
    this.profile_selection.next(client);
  }

  /**
   * Menu display selection
   *
   * @param {*} status
   * @memberof
   */
  menuDisplaySelection(status) {
    this.menu_display_selection.next(status);
  }

  /**
   * Allocate Room
   * @param {*} roomdata
   */

  AllocateRoomdata(roomdata) {
    this.roomdata.next(roomdata);
  }

  /**
   * Manager branch Change
   * @param {*} branchID
   */
  changeBranch (brid) {
    this.branchID.next(brid);
  }

  /**
   * user ID for allocate room
   * @param {*} userID
   */
  getstudentUserID (studentID) {
    this.studentUserID.next(studentID);
  }

  setBookingId(bookingId) {
    this.bookingId.next(bookingId);
  }

  /**
   * forgot password mailid store
   */

  forgotMail(data) {
    this.forgotPassmail.next(data);
   }

/**
 * checkinChecklistdata
 */
checkinChecklistdataset(data) {
  this.checkinChecklistdata.next(data);
}
/**
  * Set userDetails
   * @param {*} data
   * @memberof
   */
  userData(data) {
    this.userDetail.next(data);
  }
/**
  * Set userDetails
   * @param {*} data
   * @memberof
*/
  setTicketId(data) {
    this.ticketID.next(data);
  }

   /**
   * set wait id for waiting details show
   * @param {*} data
   * @memberof
   */
  setWaitID(data){
    this.waitID.next(data);
  }

}
