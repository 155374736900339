import { Component, OnInit, Inject } from '@angular/core';
import { MenuService } from './menu.service';
import { Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { CommonService } from '../../services/common.service';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { DataService, Response } from '../../dataservice/data.service';
import { GlobalVariable } from '../../app.component';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
export interface Options1 {
  heading?: string;
  removeFooter?: boolean;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  providers: [MenuService]
})
export class MenuComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  menuItems: any = [];
  _router: any;
  public show: boolean = false;
  routeOptions: Options1;
  routePage: any;
  constructor(
    @Inject(LOCAL_STORAGE) private storage:  WebStorageService, private location: Location,
    public menuService: MenuService,private titleService: Title, public translate: TranslateService,
    private router: Router, private route: ActivatedRoute, private commonservice: CommonService, private HttpService: DataService
  ) { }

  ngOnInit() {
    this.show = true;
    this.menuItems = [
      { title: 'Maintenance', icon: 'maintenance.svg#maintenance', route: '/maintenance' },
      { title: 'Check-in', icon: 'check_in.svg#check_in', route: '/checkin' },
      { title: 'Check-out', icon: 'checkout.svg#checkout', route: '/checkout' },
      { title: 'Residents', icon: 'residents.svg#residents', route: '/residents' },
      { title: 'Waiting List', icon: 'waiting_list.svg#waiting_list', route: '/waitinglist' },
      { title: 'Available Rooms', icon: 'available_rooms.svg#available_rooms', route: '/roomavailable' },
      { title: 'Inspection', icon: 'available_rooms.svg#available_rooms', route: '/inspection' },
      { title: 'Notice Board', icon: 'notice_board.svg#notice_board', route: '/notice-board' },
      { title: 'Dashboard', icon: 'dashboard.svg#dashboard', route: '/dashboard' },
      {title: 'Visitors log', icon: 'visitors_log.svg#visitors_log', route: '/visitors-log'}
    ];
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(
      'Qwetu | ' + newTitle
    );
  }

  public toggle(): void {
    this.show = false;
    this.commonservice.sideMenuBar(this.show);
  }
}
